import { Column } from "@antv/g2plot";
import { createRef, useEffect } from "react";
import { minutesToString } from "../ProjectItem";

const ColumnPlot = ({ data }) => {

    const plot = createRef();

    useEffect(() => {

        if (!plot.current) return;

        const columnPlot = new Column(plot.current, {
            data,
            animation: null,
            xField: 'date',
            yField: 'minutes',
            label: {
                position: 'middle', // 'top', 'bottom', 'middle',
                style: {
                    fill: '#FFFFFF',
                    opacity: 0.6,
                },
            },
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            meta: {
                date: {
                    alias: 'Дата',
                },
                minutes: {
                    alias: 'Минуты',
                },
            },
            tooltip: {
                customContent: function (title, data) {
                    let time = minutesToString(data[0]?.data?.minutes || 0);
                    return `<div class="py-2 px-1">
                        <div class="mb-2">${title}</div>
                        Время: ${time}
                    </div>`;
                },
            },
        });

        columnPlot.render();

        return () => {
            columnPlot.destroy();
        }

        // eslint-disable-next-line
    }, [data]);

    return <div>
        <div ref={plot} />
    </div>
}

export default ColumnPlot;