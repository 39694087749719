import { useEffect, useState } from "react";
import { axios } from "../../services";
import { Alert, Breadcrumb, Button, Input, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import ProjectItem from "../../components/Timer/ProjectItem";
import { Route, Routes } from "react-router";
import Project from "./Project";
import { Link } from "react-router-dom";

const Timer = () => {

    return <Routes>
        <Route path="/" element={<TimerPage />} />
        <Route path=":project" element={<Project />} />
        <Route path="*" element={<TimerPage />} />
    </Routes>
}

const TimerPage = () => {

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);

    const [formdata, setFormdata] = useState({});
    const [saveProject, setSaveProject] = useState(false);
    const [saveError, setSaveError] = useState(null);

    useEffect(() => {

        axios.get('timer/projects')
            .then(({ data }) => {
                setProjects(data);
            })
            .catch(e => {

            })
            .then(() => {
                setLoading(false);
            });

    }, []);

    const storeProject = async (data) => {
        try {
            const response = await axios.post('timer/projects', data);
            setProjects(p => ([response.data, ...p]));
            setOpenAddModal(false);
        } catch (e) {
            setSaveError(e?.response?.data?.message || e?.message);
        }
        setSaveProject(false);
    }

    useEffect(() => {
        if (!saveProject) return;
        storeProject(formdata);
        // eslint-disable-next-line
    }, [saveProject]);

    return <div className="w-full max-w-4xl mx-auto mt-6 p-2">

        <Breadcrumb
            className="mb-3"
            items={[
                { title: <Link to="/">Главная</Link> },
                { title: "Таймер" },
            ]}
        />

        <div className="flex items-center justify-between mb-9">
            <h1 className="m-0 cursor-default">Учет рабочего времени</h1>
            <div>
                <FontAwesomeIcon
                    icon={faPlusSquare}
                    className="text-blue-400 hover:text-blue-600 mx-3 cursor-pointer"
                    onClick={() => setOpenAddModal(p => !p)}
                    size="xl"
                />
            </div>
        </div>

        <Modal
            open={openAddModal}
            onCancel={() => setOpenAddModal(false)}
            footer={null}
            title="Добавить проект"
            afterClose={() => {
                setSaveError(null);
                setSaveProject(false);
                setFormdata({});
            }}
            children={<div className="flex min-h-full items-center justify-center px-2 sm:px-6 lg:px-8">
                <div className="mt-8 space-y-6">
                    <div>
                        <div>
                            <label htmlFor="login" className="mb-2 px-1">
                                <strong>Наименование проекта</strong>
                            </label>
                            <Input
                                id="title"
                                placeholder="Введите наименование проекта"
                                className="w-full"
                                name="title"
                                size="large"
                                value={formdata.title || ""}
                                onChange={({ target }) => setFormdata(p => ({ ...p, title: target?.value }))}
                                disabled={saveProject}
                                onKeyUp={e => {
                                    e.preventDefault();
                                    e.key === "Enter" && setSaveProject(true);
                                }}
                            />
                        </div>
                    </div>
                    {saveError && <Alert message={saveError} type="error" showIcon />}
                    <Button
                        type="primary"
                        size="large"
                        block
                        className="group text-sm font-semibold text-white"
                        children={<>Добавить</>}
                        onClick={() => setSaveProject(true)}
                        loading={saveProject}
                    />
                </div>
            </div>}
        />

        {(!loading && projects.length === 0) && <div className="text-center mt-10 opacity-50">
            <small>Необходимо добавить рабочий проект</small>
        </div>}

        {(!loading && projects.length > 0) && <div className="flex flex-col gap-3">
            {projects.map(item => <ProjectItem
                key={item.id}
                data={item}
            />)}
        </div>}

    </div>
}

export default Timer;