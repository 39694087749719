import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Main/Main"
import NotFound from "./Errors/NotFound";
import App from "./Dashboard/App";
import { CRM, ChatGpt, Disk, Profile, Timer } from "../containers";
import Login from "./Auth/Login";
import { useSelector } from "react-redux";
window.io = require('socket.io-client');

const DashBoard = () => {

    return <App>
        <Routes>
            <Route path="/crm/*" element={<CRM />} />
            <Route path="/chatgpt/*" element={<ChatGpt />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/disk/*" element={<Disk />} />
            <Route path="/timer/*" element={<Timer />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </App>
}

const Router = () => {

    const isLoading = Boolean(useSelector(stage => stage?.app?.user?.id));

    return <Routes>
        <Route path="/" element={<Main />} />
        <Route path="login" element={<Login />} />
        {isLoading
            ? <Route path="*" element={<DashBoard />} />
            : <Route path="*" element={<Login />} />
        }
    </Routes>
}

export default Router;