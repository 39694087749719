import { TinyColumn } from '@antv/g2plot';
import { createRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import ToggleButton from './ToggleButton';

export function minutesToString(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:00`;
}

const ProjectItem = props => {

    const [data, setData] = useState(props.data || {});
    const columns = createRef();
    const navigate = useNavigate();

    useEffect(() => {
        const plot = new TinyColumn(columns.current, {
            height: 40,
            autoFit: false,
            data: data.columns || [],
            animation: null,
            width: 300,
            columnStyle: {
                stroke: null,
            },
            state: {
                active: {
                    style: {
                        fill: '#457ce5',
                        stroke: null,
                        lineWidth: 0,
                    },
                },
            },
            tooltip: {
                customContent: function (x, data) {
                    return minutesToString(data[0]?.data?.y || 0);
                },
            },
        });
        plot.render();
        return () => {
            plot.destroy();
        }
        // eslint-disable-next-line
    }, [data?.columns]);

    return <div className={`${data.inProcess ? `bg-green-100 hover:bg-green-200` : `bg-slate-50 hover:bg-slate-100`} rounded px-4 py-2 flex items-center justify-between gap-3`}>
        <div className='flex-grow'>
            <strong className='cursor-pointer hover:text-blue-800' onClick={() => navigate(`/timer/${data.id}`)}>
                {data.title}
            </strong>
        </div>
        <div className='border-b-slate-300 border-solid border-t-0 border-r-0 border-l-0 border-b'>
            <div ref={columns}></div>
        </div>
        <div className="w-[20px] text-right">
            <ToggleButton data={data} setData={setData} />
        </div>
    </div>
}

export default ProjectItem;