import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppLoading from "./components/Parts/AppLoading";
import Router from "./components/Router";
import { useActions } from "./hooks/useActions";
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);

const App = () => {

    const { fetchApp } = useActions();
    const { loading } = useSelector(s => s.app);

    React.useEffect(() => {
        fetchApp();
        // eslint-disable-next-line
    }, []);

    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark')
    }

    if (loading) {
        return <AppLoading />
    }

    return <BrowserRouter>
        <Router />
    </BrowserRouter>

}

export default App;