import { faRotateRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { axios } from "../../services";
import { message } from "antd";

const ResetButton = ({ data, setData }) => {

    const [loading, setLoading] = useState(false);

    const fetchToggle = useCallback(() => {
        setLoading(true);
        axios.post(`timer/${data.id}/reset`)
            .then(({ data }) => {
                setData(data);
            })
            .catch(e => {
                message.error({ content: axios.getError(e) });
            })
            .then(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [data]);

    return <div title="Сбросить счетчик">
        {!loading && <FontAwesomeIcon
            icon={faRotateRight}
            className={`text-gray-500 hover:text-gray-700 cursor-pointer`}
            onClick={() => fetchToggle()}
            size='xl'
        />}
        {loading && <FontAwesomeIcon
            icon={faSpinner}
            spin
        />}
    </div>
}

export default ResetButton;