// import { useEffect } from "react";
// import { useNavigate } from "react-router";

import Main from "../Main/Main";

const Login = () => {

    // const navigate = useNavigate();

    // useEffect(() => {
    //     navigate("/");
    //     // eslint-disable-next-line
    // }, []);

    return <Main login />;
}

export default Login;