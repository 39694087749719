import { Alert, Breadcrumb, Col, Row, Skeleton, Statistic } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { axios } from "../../services";
import ToggleButton from "../../components/Timer/ToggleButton";
import { minutesToString } from "../../components/Timer/ProjectItem";
import { Link } from "react-router-dom";
import ColumnPlot from "../../components/Timer/Charts/ColumnPlot";
import ResetButton from "../../components/Timer/ResetButton";

const Project = () => {

    const { project } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {

        axios.get(`timer/projects/${project}`)
            .then(({ data }) => {
                setData(data);
            })
            .catch(e => {
                setError(axios.getError(e));
            })
            .then(() => {
                setLoading(false);
            });

        return () => {
            setData({});
        }

    }, [project]);

    return <div className="w-full max-w-4xl mx-auto mt-6 cursor-default p-2">

        <Breadcrumb
            className="mb-3"
            items={[
                { title: <Link to="/">Главная</Link> },
                { title: <Link to="/timer">Таймер</Link> },
                { title: data.title },
            ]}
        />

        <div className="mb-5 flex gap-2 items-center">
            <h1 className="m-0 flex-grow">Учет рабочего времени</h1>
        </div>

        {loading && <Skeleton active={true} />}

        {error && <Alert message={error} type="error" />}

        {(!loading && !error) && <>

            <div className="mb-5 flex gap-2">
                <div className="flex-grow">
                    <Row gutter={16}>
                        <Col span={16}>
                            <Statistic title="Время наработки" value={data?.minutesCount || 0} formatter={minutesToString} />
                        </Col>
                    </Row>
                </div>
                <ResetButton data={data} setData={setData} fullData />
                <ToggleButton data={data} setData={setData} fullData />
            </div>

            <div className="mt-10">
                <ColumnPlot data={data.columnsData || []} />
            </div>

        </>}

    </div>
}

export default Project;