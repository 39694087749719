import { faPlay, faSpinner, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { axios } from "../../services";
import { message } from "antd";

const ToggleButton = ({ data, setData, fullData }) => {

    const [loading, setLoading] = useState(false);

    const fetchToggle = useCallback(() => {
        setLoading(true);
        axios.post(`timer/${data.id}/toggle`, { fullData: Boolean(fullData) })
            .then(({ data }) => {
                setData(data);
            })
            .catch(e => {
                message.error({ content: axios.getError(e) });
            })
            .then(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [data]);

    return <>
        {!loading && <FontAwesomeIcon
            icon={data.inProcess ? faStop : faPlay}
            className={`${data.inProcess ? `text-red-500 hover:text-red-400` : `text-green-500 hover:text-green-400`} cursor-pointer`}
            onClick={() => fetchToggle()}
            size='xl'
        />}
        {loading && <FontAwesomeIcon
            icon={faSpinner}
            spin
        />}
    </>
}

export default ToggleButton;