import { axios } from "../../services";
import { API_TOKEN_ITEM_KEY } from "../reducers/appReducer";
import * as TYPES from "../types/app";
import Echo from 'laravel-echo';
import { message } from "antd";

window.io = require('socket.io-client');

const echoConnect = async () => {

    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.REACT_APP_WS_PROTOCOL + "://" + process.env.REACT_APP_WS_HOST,
        path: process.env.REACT_APP_WS_PATH ? "/" + process.env.REACT_APP_WS_PATH : "",
        auth: {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('kolgaev_api_token')
            }
        },
    });

}

export const fetchApp = () => {

    return async dispatch => {

        let params = (new URL(document.location)).searchParams;
        if (params.get("token")) {
            localStorage.setItem(API_TOKEN_ITEM_KEY, params.get("token"));
        }

        dispatch({ type: TYPES.FETCH_APP_START });

        try {
            const response = await axios.get('app');
            response?.data?.user && await echoConnect();
            dispatch({ type: TYPES.FETCH_APP_START_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({ type: TYPES.FETCH_APP_START_ERROR, payload: e });
        }
    }
}

export const fetchLogin = (formdata, setLoading = null, success = null) => {

    return async dispatch => {

        typeof setLoading == "function" && setLoading(true);

        // dispatch({ type: TYPES.FETCH_CATCH, payload: null });

        try {
            const response = await axios.post('auth/login', formdata);
            response?.data?.user && await echoConnect();
            typeof success == "function" && success(response.data);
            dispatch({ type: TYPES.FETCH_APP_USER_LOGIN, payload: response.data });
        } catch (e) {
            dispatch({ type: TYPES.FETCH_CATCH, payload: e });
        }

        typeof setLoading == "function" && setLoading(false);
    }
}

export const fetchLogout = (success) => {
    return async dispatch => {
        dispatch({ type: TYPES.FETCH_APP_USER_LOGOUT, payload: { isFetchLogout: true } });
        try {
            const response = await axios.delete('auth/logout');
            // response?.data?.user && await echoConnect();
            typeof success == "function" && success(response.data);
            message.success({ content: response.data.message });
            dispatch({
                type: TYPES.FETCH_APP_USER_LOGOUT, payload: {
                    user: null,
                    isFetchLogout: false,
                    token: null,
                    parts: null,
                }
            });
        } catch (e) {
            message.error({ content: "Не получилось выполнить запрос" });
            dispatch({ type: TYPES.FETCH_APP_USER_LOGOUT, payload: { isFetchLogout: false } });
        }
    }
}

export const setHeaderRight = component => ({ type: TYPES.SET_HEADER_RIGHT_COMPONENT, payload: component });

export const setProfileData = data => ({ type: TYPES.SET_PROFILE_DATA, payload: data });
